
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectAll } from './notification.reducer';
import { NotificationState } from '../index';
import { SettingsStateKeys } from '@libs/entity-lib';

export const selectNotificationGlobalState =
  createFeatureSelector<NotificationState>(SettingsStateKeys.NOTIFICATIONS);

// Notifications Settings selector
export const selectNotificationState = createSelector(
  selectNotificationGlobalState,
  (state: NotificationState) => state?.notifications
);

export const selectTokenWeb = createSelector(
  selectNotificationState,
  (state): string | null => state?.tokenWeb
);

export const selectTokenMobile = createSelector(
  selectNotificationState,
  (state): string | null => state?.tokenMobile
);

export const selectCountUserNotifications = createSelector(
  selectNotificationState,
  (state): number => state?.total ?? 0
);

export const selectLoading = createSelector(
  selectNotificationState,
  (state): boolean => state?.loading ?? false
);

export const selectNotifications = createSelector(selectNotificationState, selectAll );