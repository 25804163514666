<ion-header class="ion-no-border">
  <ion-toolbar class="header-toolbar">
    <ion-buttons slot="start">
      @if (canalType.SPORT_CENTER === canal()) {
        <ion-menu-button class="header-menu-button" autoHide="false"></ion-menu-button>
      }
      @if (canalType.SPORT_CENTER !== canal()) {
        <ion-back-button></ion-back-button>
      }
    </ion-buttons>

    <ion-title class="header-title" translate>NOTIFICATION.TITLE</ion-title>

    <ion-icon slot="end" class="header-icon" (click)="showConfirmationDialog()" name="trash-outline"></ion-icon>
    <ion-icon slot="end" class="header-icon"
    (click)="redirectToNotificationSettings()" name="construct"></ion-icon>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

  <div class="content-container">

    @if (isLoading$ | async) {
      <ion-content class="ion-text-center">
        <ion-spinner name="dots"></ion-spinner>
      </ion-content>
    } @else {
      @for(notification of notification$ | async; track notification.id) {
        <ion-row class="ion-no-padding card-list">
          <ion-col [size]="12" class="ion-no-padding">
            <notification-card [notification]="notification"></notification-card>
          </ion-col>
        </ion-row>
      }
      @if((notification$ | async)?.length === 0) {
        <div class="ion-text-center">
          <img src="assets/icon/colors/features/bell.svg" width="70" />
          <div class="ion-padding">
            <span translate>NOTIFICATION.YOU_HAVE_NO_NOTIFICATIONS</span>
          </div>
        </div>

        <ion-card (click)="redirectToNotificationSettings()">
          <div class="ion-text-center">
            <div class="ion-padding">
              <span>Ativar Notificações</span>
            </div>
            <ion-icon slot="end" style="font-size: 30px; margin-right: 10px; margin-bottom: 5px; color: var(--ion-color-tertiary);" name="construct"></ion-icon>
            <div class="ion-padding">
              <span translate>Clique aqui para ativar suas notificações</span>
            </div>
          </div>
        </ion-card>

      }
    }

    <ng-template #content>

      @for(notification of notification$ | async; track notification.id) {
        <ion-row class="ion-no-padding card-list">
          <ion-col [size]="12" class="ion-no-padding">
            <notification-card [notification]="notification"></notification-card>
          </ion-col>
        </ion-row>
      }

      @if((notification$ | async)?.length === 0) {
        <div class="ion-text-center">
          <img src="assets/icon/colors/features/bell.svg" width="70" />
          <div class="ion-padding">
            <span translate>NOTIFICATION.YOU_HAVE_NO_NOTIFICATIONS</span>
          </div>
        </div>
      }
    </ng-template>

    <br/>
    
  </div>

</ion-content>