import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  finalize,
  from,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
} from 'rxjs';
import * as fromNotificationActions from './notification.actions';
import { Store } from '@ngrx/store';
import { NotificationService } from '../../services/notification/notification.service';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { NotificationRegisterRequest, CanalTokenType } from '@libs/entity-lib';
import {
  PushNotifications
} from '@capacitor/push-notifications';
import { AlertController, ToastController } from '@ionic/angular';

@Injectable()
export class NotificationEffects {
  fetchAllNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNotificationActions.fetchAllNotifications),
      switchMap(() =>
        this.notificationService.fetchAll().pipe(
          map((notifications) => {
            return fromNotificationActions.fetchAllNotificationsSuccessfully({
              notificationList: notifications,
            });
          }),
          catchError((error) => {
            this.presentToastTop('Falha o sincronizar notificações! :(', 2500, 'danger');
            return of(
              fromNotificationActions.fetchAllNotificationsFailed({ error })
            );
          })
        )
      )
    )
  );

  fetchNotificationTokens$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNotificationActions.fetchNotificationTokens),
      switchMap(() =>
        this.notificationService.fetchNotificationTokens().pipe(
          map((notificationToken) => fromNotificationActions.fetchNotificationTokenSuccessfully({ notificationToken })),
          catchError((error) => of(fromNotificationActions.fetchNotificationTokenFailed({ error })))
        )
      )
    )
  );

  readNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNotificationActions.readNotification),
      switchMap(({notification}) =>
        this.notificationService.readNotification(notification).pipe(
          map(() => {
            this.presentToastTop(
              'Notificações lida com sucesso!',
              2500,
              'success'
            );
            this.store.dispatch(fromNotificationActions.fetchNotificationTokens());
            return fromNotificationActions.removeNotificationById({
              id: notification.id
            });
          }),
          catchError((error) => {
            return of(
              fromNotificationActions.readNotificationsFailed({ error })
            );
          })
        )
      )
    )
  );

  readAllNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNotificationActions.readAllNotifications),
      tap(() => this.showLoader()),
      switchMap(() =>
        this.notificationService.readAllNotification().pipe(
          map(() => {
            this.presentToastTop(
              'Todas notificações deletadas!',
              2500,
              'success'
            );
            return  fromNotificationActions.readAllNotificationsSuccessfully();
          }),
          catchError((error) => {
            const errorMessage = error?.error?.message ?? error?.message ?? "Entre em contato com o Social Sport.";
            if (error.status === 500) {
              this.presentToastTop(
                'Pedimos desculpas, mas ocorreu um erro inesperado em nosso servidor.',
                2500,
                'danger'
              );
            } else {
              this.presentToastTop(
                errorMessage,
                2500,
                'danger'
              );
            }
            return of(fromNotificationActions.readAllNotificationsFailed({ error }));
          }),
          finalize(() => this.hideLoader())
        )
      )
    )
  );

  registerTokenWebAndMobile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNotificationActions.registerToken),
      switchMap(({ notificationRequest }) =>
        this.notificationService.registerToken(notificationRequest).pipe(
          map(() => fromNotificationActions.registerTokenSuccessfully()),
          catchError((error) => {
            const errorMessage = error?.error?.message ?? error?.message ?? "Entre em contato com o Social Sport.";
            this.presentAlert('Error', errorMessage);
            return of(
              fromNotificationActions.fetchAllNotificationsFailed({ error })
            );
          })
        )
      )
    )
  );

  requestPermissionWithMobile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNotificationActions.requestPermissionsWithMobile),
      mergeMap(() =>
        from(PushNotifications.requestPermissions()).pipe(
          map((result) => {
            if (result.receive === 'granted') {
              // Register with Apple / Google to receive push via APNS/FCM
              PushNotifications.register();
              return fromNotificationActions.registerTokenSuccessfully();
            } else {
              return fromNotificationActions.requestPermissionsWithMobileFailed({
                error: JSON.stringify(result),
              })
            }
          }),
          catchError((error) => {
            const errorMessage = error?.error?.message ?? error?.message ?? "Entre em contato com o Social Sport.";
            this.presentAlert('Erro', errorMessage);
            return of(
              fromNotificationActions.requestPermissionsWithMobileFailed({
                error,
              })
            );
          })
        )
      )
    )
  );

  requestPermissionWeb$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNotificationActions.requestPermissionsWithWeb),
      switchMap(() =>
        this.angularFireMessaging.requestToken.pipe(
          map((token) => {
            if (token) {
              const notificationRequest = {
                token, canalTokenType: CanalTokenType.WEB
              } as NotificationRegisterRequest;
              this.store.dispatch(fromNotificationActions.registerTokenWebSuccess({ token }));
              return fromNotificationActions.registerToken({ notificationRequest });
            } else {
              throw new Error('Token not found!');
            }
          }),
          catchError((error) =>
            of(
              fromNotificationActions.requestPermissionsWithMobileFailed({
                error: JSON.stringify(error),
              })
            )
          )
        )
      )
    )
  );

  resetPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNotificationActions.resetToken),
      switchMap(({ notificationRequest }) =>
        this.notificationService.registerToken(notificationRequest).pipe(
          map(() => {
            return fromNotificationActions.resetTokenSuccessfully({
              canalTokenType: notificationRequest.canalTokenType, token: null
            });
          }),
          catchError((error) => {
            const errorMessage = error?.error?.message ?? error?.message ?? "Entre em contato com o Social Sport.";
            if (error.status === 500) {
              this.presentToastTop(
                'Pedimos desculpas, mas ocorreu um erro inesperado em nosso servidor.',
                2500,
                'danger'
              );
            } else {
              this.presentToastTop(
                errorMessage,
                2500,
                'danger'
              );
            }
            return of(
              fromNotificationActions.resetTokenFailed({
                error: JSON.stringify(error),
              })
            );
          })
        )
      )
    )
  );

  getToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNotificationActions.getToken),
      mergeMap(() =>
        from(this.angularFireMessaging.getToken).pipe(
          map((token) => {
            if (token) {
              // Request granted. Register to receive notifications
              return fromNotificationActions.registerTokenWebSuccess({ token });
            } else {
              // Permission denied. Handle the error
              return fromNotificationActions.requestPermissionsWithMobileNotAllowed(
                {
                  error: 'Permission denied.',
                }
              );
            }
          }),
          catchError((error) =>
            of(
              fromNotificationActions.requestPermissionsWithMobileFailed({
                error,
              })
            )
          )
        )
      )
    )
  );

  countNotificationTokens$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNotificationActions.countUserNotifications),
      tap(() => {
        console.log('countUserNotifications');
      }),
      switchMap(() =>
        this.notificationService.countNotificationHistory().pipe(
          map((response) => fromNotificationActions.countUserNotificationsSuccessfully({ total: response.total })),
          catchError((error) => of(fromNotificationActions.countUserNotificationsFailed({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private store: Store,
    private angularFireMessaging: AngularFireMessaging,
    private toastController: ToastController,
    private alertController: AlertController
  ) {}

  private async showLoader() {
    this.store.dispatch(fromNotificationActions.setLoading({ loading: true }));
  }

  private hideLoader() {
    this.store.dispatch(fromNotificationActions.setLoading({ loading: false }));
  }

  async presentToastTop(msg: string, dur: number, col: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: dur,
      position: 'bottom',
      color: col
    });

    await toast.present();
  }

  async presentAlert(subHeader: string, message: string) {
    const alert = await this.alertController.create({
      header: '',
      subHeader,
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }

}
