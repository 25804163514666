import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, filter, map, Observable, Subscription, take } from 'rxjs';
import {
  selectCanal,
} from '../../store/settings/settings.selectors';
import {
  NotificationRegisterRequest,
  Notification,
  Canal,
  NotificationSettings,
  NotificationToken,
  NotificationRoutesServiceEnum,
  getllAllNotificationPath,
  getAllNotificationSettingsPath,
  getNotificationTokenPath,
  PutNotificationSettings,
  getNotificationPath,
  NotificationCountResponse
} from '@libs/entity-lib';
import { commonEnvironment } from '@libs/shared/util-environments';

@Injectable()
export class NotificationService implements OnDestroy {
  private selectCanal$ = this.store.select(selectCanal);
  
  private token$$ = new BehaviorSubject<any>(null);
  token$ = this.token$$.asObservable();

  private baseUrl: string;
  private canal: string;

  private subscriptions = new Subscription();

  constructor(private http: HttpClient, private store: Store) {
    this.selectCanal$.pipe(
      filter((canal) => canal !== Canal.UNDEFINED),
      take(1)
    ).subscribe(canal => {
      this.canal = canal;
      this.setBaseUrlByCanal(canal);
    });
  }

  setBaseUrlByCanal(canal: Canal) {
    this.baseUrl = commonEnvironment.apps[canal].baseUrl;
  }


  fetchAll(): Observable<Notification[]> {
    const url = `${this.baseUrl}${getllAllNotificationPath()}`;
    return this.http
      .get<Notification[]>(url)
      .pipe(map((response: any) => response));
  }

  fetchAllSettings(): Observable<NotificationSettings[]> {
    const url = `${this.baseUrl}${getAllNotificationSettingsPath(this.canal)}`;
    return this.http
      .get<NotificationSettings[]>(url)
      .pipe(map((response: any) => response));
  }

  fetchNotificationTokens(): Observable<NotificationToken> {
    const url = `${this.baseUrl}${getNotificationTokenPath(this.canal)}`;
    return this.http
      .get<NotificationToken>(url)
      .pipe(map((response: any) => response));
  }

  registerNotificationSettings(
    request: PutNotificationSettings
  ): Observable<NotificationSettings[]> {
    const url = `${this.baseUrl}${NotificationRoutesServiceEnum.REGISTER_NOTIFICATION_SETTINGS}`;
    return this.http
      .put<PutNotificationSettings>(url, request)
      .pipe(map((response: any) => response));
  }

  registerToken(
    request: NotificationRegisterRequest
  ): Observable<Notification[]> {
    const url = `${this.baseUrl}${getNotificationPath(this.canal)}`;
    return this.http
      .post<Notification[]>(url, request)
      .pipe(map((response: any) => response));
  }

  readNotification(notification: Notification): Observable<Notification> {
    const url = `${this.baseUrl}${NotificationRoutesServiceEnum.FETCH_NOTIFICATIONS_HISTORY}/${notification.id}`;
    return this.http
      .delete<Notification>(url)
      .pipe(map((response: any) => response));
  }

  readAllNotification(): Observable<Notification> {
    const url = `${this.baseUrl}${NotificationRoutesServiceEnum.DELETE_ALL_NOTIFICATIONS}`;
    return this.http
      .delete<Notification>(url)
      .pipe(map((response: any) => response));
  }

  countNotificationHistory(): Observable<NotificationCountResponse> {
    const url = `${this.baseUrl}${NotificationRoutesServiceEnum.COUNT_NOTIFICATION_HISTORY}`;
    return this.http
      .get<Notification>(url)
      .pipe(map((response: any) => response));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
