@if (notification(); as notification) {
  <ion-card>
    <ion-card-content>

      @for(line of notification.message.split('\n'); track line) {
        <p>{{ line }}</p>
      }
      <p>
        <strong>Data de Envio: {{ notification.sendDate | date: 'dd/MM/yyyy hh:mm'  }}</strong>
      </p>
      <ion-button fill="outline" expand="full" (click)="readNotification()">
        <ion-icon slot="start" name="checkmark-circle-outline"></ion-icon>
        <span translate>NOTIFICATION.MARK_AS_READ</span>
      </ion-button>
    </ion-card-content>
  </ion-card>
}